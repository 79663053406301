import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import Customer from '@models/Customer';
import Sector from '@models/Sector';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';
import SectorReservationInfo from '@models/SectorReservationInfo';
import SmartSwitch from '@components/input/SmartSwitch';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import {QRCodeSVG, QRCodeCanvas} from 'qrcode.react';

export interface ISectorFormProps extends IInjectedFormModelProps<SectorReservationInfo> {
    sectorId: number | null,
}

export interface ISectorFormState {
    imgUrlToShow: string
}

class SectorReservationInfoForm extends React.Component<ISectorFormProps & IFormModelProps<SectorReservationInfo>, ISectorFormState> {
  constructor(props: ISectorFormProps & IFormModelProps<SectorReservationInfo>) {
    super(props);
    
    if(props.model.active == null){
        props.model.active = false
    }

    if(props.model.sector == null){
        props.model.sectorId = props.sectorId
    }
    
    this.state = {
        imgUrlToShow: props.model.image
    }
    this.change_url = this.change_url.bind(this);
  }

  public change_url(e){
    this.setState({imgUrlToShow: e})
  }

  public render() {
    const {model, submit, submitting, id} = this.props;
    return (
      <SidebarLayout
        title={id ? "Modification des informatons de godin lib" : "Création des informations de godin lib"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{id ? "Modifier les inforations de godinlib" : "Créer les informations de godin lib"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="name" label="Nom affiché dans godin lib" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputTextarea model={model} name="text" label="Texte affiché dans godin lib" containerClassName="mb-3" />
            </div>
            <div className="col-md-12 col-12">
                <SmartSwitch model={model} id="active" name="active" containerClassName='ms-auto' label={"Disponible sur godin lib"}/>
            </div>
            <div className="col-md-12 col-12">
            <SmartInputText model={model} name="image" label="Url du logo" containerClassName="mb-3" onChange={this.change_url} />
            <img src={model.image} width={161}/>
            </div>
            
            <div className="col-md-12 col-12" style={{padding: "1rem"}}>
              <QRCodeCanvas value={"https://reservation.godin-eau.fr/sector/" + model.sectorId }/>
            </div>
          </div>
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<ISectorFormProps>(SectorReservationInfoForm, {modelClass: SectorReservationInfo})